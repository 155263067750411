import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    getConfig() {
        //console.log('TOKEN', store.getters.jwtToken);
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`,
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            }
        };
    },
    slugify(value) {
        return services.http.post(`/v1.0/eventmanager/slugify`, qs.stringify({ value: value}), this.getConfig());
    },
    getOwnedEvents(userCreated, orgId) {
        return services.http.get(`/v1.0/eventmanager?userCreated=${userCreated||''}&orgId=${orgId||''}&archived=false`, this.getConfig());
    },
    getArchivedEvents(userCreated, orgId) {
        return services.http.get(`/v1.0/eventmanager?userCreated=${userCreated||''}&orgId=${orgId||''}&archived=true`, this.getConfig());
    },
    getRecentEvents() {
        return services.http.get(`/v1.0/eventmanager/recent`, this.getConfig());
    },
    getUpcomingEvents() {
        return services.http.get(`/v1.0/eventmanager/upcoming`, this.getConfig());
    },
    getEventsUpForRenewal() {
        return services.http.get(`/v1.0/eventmanager/renewals`, this.getConfig());
    },
    markEventAsDismissedForRenewal(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/renewal/dismiss`, {}, this.getConfig());
    },
    remindForRenewal(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/renewal/remind`, {}, this.getConfig());
    },
    create(model, userCreated) {
        return services.http.post(`/v1.0/eventmanager/?userCreated=${userCreated||''}`, model, this.getConfig());
    },
    createTrial(model) {
        return services.http.post(`/v1.0/eventmanager/trial`, model, this.getConfig());
    },
    get(id, includeSeries) {
        return services.http.get(`/v1.0/eventmanager/${id}?includeSeries=${includeSeries||''}`, this.getConfig());
    },
    put(model) {
        return services.http.put(`/v1.0/eventmanager/${model.id}`, model, this.getConfig());
    },
    putRegConfig(id, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/config/registration`, model, this.getConfig());
    },
    putOnboardingConfig(id, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/config/onboarding`, model, this.getConfig());
    },
    getLocalized(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/translations`, this.getConfig());
    },
    saveLocalized(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/translations`, model, this.getConfig());
    },
    archiveEvent(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/archive`, {}, this.getConfig());
    },
    unarchiveEvent(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/unarchive`, {}, this.getConfig());
    },
    postRace(id, request) {
        return services.http.post(`/v1.0/eventmanager/${id}/races`, request, this.getConfig());
    },
    getLicenseStatus(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/license`, this.getConfig());
    },
    applyLicense(id, license) {
        return services.http.post(`/v1.0/eventmanager/${id}/license/apply?license=${license}`, {}, this.getConfig());
    },
    enableExtendedDuration(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/license/extended-duration`, {}, this.getConfig());
    },
    upgradeLicense(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/license/upgrade`, {}, this.getConfig());
    },
    getStats(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/stats`, this.getConfig());
    },
    getLogs(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/logs`, this.getConfig());
    },
    getTasks(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/tasks`, this.getConfig());
    },
    getTask(id, taskId) {
        return services.http.get(`/v1.0/eventmanager/${id}/tasks/${taskId}`, this.getConfig());
    },
    addTrainingPlan(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/training-plans`, model, this.getConfig());
    },
    addStreakChallenge(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/generate/streak`, model, this.getConfig());
    },
    addLastManStandingChallenge(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/generate/lastmanstanding`, model, this.getConfig());
    },
    addSubleaderboards(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/generate/subleaderboards`, model, this.getConfig());
    },
    addClaimChallenge(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/generate/claimchallenge`, model, this.getConfig());
    },
    applyTemplateToRace(id, raceId, templateId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/template`, { id: templateId }, this.getConfig());
    },
    disconnectRaceTemplate(id, raceId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/${raceId}/template`, this.getConfig());
    },
    submitForApproval(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/submit`, model, this.getConfig());
    },
    linkWithSlack(id, slackChannelId) {
        return services.http.post(`/v1.0/eventmanager/${id}/link-slack?slackChannel=${slackChannelId}`, {}, this.getConfig());
    },
    linkWithTeams(id, teamsInfoJson) {
        return services.http.post(`/v1.0/eventmanager/${id}/link-teams?teamsInfoJson=${teamsInfoJson}`, {}, this.getConfig());
    },
    unlinkFromSlack(id) {
        return services.http.delete(`/v1.0/eventmanager/${id}/link-slack`, this.getConfig());
    },
    unlinkFromTeams(id) {
        return services.http.delete(`/v1.0/eventmanager/${id}/link-teams`, this.getConfig());
    },
    getEventsToLink(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/link-next`, this.getConfig());
    },
    linkNext(id, request) {
        return services.http.post(`/v1.0/eventmanager/${id}/link-next`, request, this.getConfig());
    },
    linkOrg(id, orgId) {
        return services.http.post(`/v1.0/eventmanager/${id}/link-org?orgId=${orgId}`, {}, this.getConfig());
    },
    unlinkFromOrg(id) {
        return services.http.delete(`/v1.0/eventmanager/${id}/link-org`, this.getConfig());
    },
    lock(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/lock`, {}, this.getConfig());
    },
    unlock(id) {
        return services.http.delete(`/v1.0/eventmanager/${id}/lock`, this.getConfig());
    },
    duplicate(id, newName, request) {
        if (!request) {
            request = { name: newName };
        }
        return services.http.post(`/v1.0/eventmanager/${id}/duplicate`, request, this.getConfig());
    },
    duplicateAsSimulation(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/create-simulator`, null, this.getConfig());
    },
    validateEventTemplate(id, request) {
        return services.http.post(`/v1.0/eventmanager/${id}/template/validate`, request, this.getConfig());
    },
    applyEventTemplate(id, request) {
        return services.http.post(`/v1.0/eventmanager/${id}/template/apply`, request, this.getConfig());
    },
    connectEventTemplate(id, templateId) {
        return services.http.post(`/v1.0/eventmanager/${id}/template/connect?templateId=${templateId}`, {}, this.getConfig());
    },
    disconnectEventTemplate(id) {
        return services.http.delete(`/v1.0/eventmanager/${id}/template`, this.getConfig());
    },
    upload(id, type, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/upload/${type}`, model, this.getConfig());
    },
    import(id, type, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/import/${type}`, model, this.getConfig());
    },
    exportRaceGpx(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/gpx`, this.getConfig());
    },
    uploadRaceGpx(id, raceId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/upload/gpx`, model, this.getConfig());
    },
    uploadRaceCourse(id, raceId, encodedPolyline) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/upload/course`, qs.stringify({ encodedPolyline: encodedPolyline}), this.getConfig());
    },
    deleteRaceCourse(id, raceId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/${raceId}/upload/course`, this.getConfig());
    },
    uploadRaceGeofence(id, raceId, geofencePoly) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/upload/geofence`, qs.stringify({ poly: geofencePoly}), this.getConfig());
    },
    uploadRaceResults(id, raceId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/upload/results`, model, this.getConfig());
    },
    checkLiveResultsUrl(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/live-results`, this.getConfig());
    },
    uploadRegistrations(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/upload/registrations`, model, this.getConfig());
    },
    uploadPreloadedActivities(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/upload/preloadedactivities`, model, this.getConfig());
    },
    getPreloadedActivitiesInfo(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/preloadedactivities`, this.getConfig());
    },
    uploadTeamAssignment(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/upload/teamassignment`, model, this.getConfig());
    },
    getTeamAssignmentStatus(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/upload/teamassignment`, this.getConfig());
    },
    getRegistrations(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/registrations`, this.getConfig());
    },
    putRegistration(id, code, request) {
        return services.http.put(`/v1.0/eventmanager/${id}/registrations/${code}`, request, this.getConfig());
    },
    deleteRegistration(id, code) {
        return services.http.delete(`/v1.0/eventmanager/${id}/registrations/${code}`, this.getConfig());
    },
    forceRegistrationSync(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/registrations/sync`, {}, this.getConfig());
    },
    getParticipants(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/participants`, this.getConfig());
    },
    getParticipant(id, profileId) {
        return services.http.get(`/v1.0/eventmanager/${id}/participants/${profileId}`, this.getConfig());
    },
    putParticipant(id, profileId, request) {
        return services.http.put(`/v1.0/eventmanager/${id}/participants/${profileId}`, request, this.getConfig());
    },
    requestParticipantProviderSync(id, profileId, provider, days) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/connections/${provider}/sync?daysInPast=${days}`, null, this.getConfig());
    },
    getProfile(id, profileId) {
        return services.http.get(`/v1.0/eventmanager/${id}/profiles/${profileId}`, this.getConfig());
    },
    disconnectParticipant(id, profileId, deleteResults) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/disconnect`, qs.stringify({ deleteResults: deleteResults}), this.getConfig());
    },
    reprocessParticipant(id, profileId) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/reprocess`, {}, this.getConfig());
    },
    sendParticipantDeviceAction(id, profileId, action) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/device-send/${action}`, {}, this.getConfig());
    },
    getParticipantMeta(id, profileId, key) {
        return services.http.get(`/v1.0/eventmanager/${id}/participants/${profileId}/meta/${key}`, this.getConfig());
    },
    deleteParticipantMeta(id, profileId, key) {
        return services.http.delete(`/v1.0/eventmanager/${id}/participants/${profileId}/meta/${key}`, this.getConfig());
    },
    getParticipantPhotos(id, profileId) {
        return services.http.get(`/v1.0/eventmanager/${id}/participants/${profileId}/photos`, this.getConfig());
    },
    awardParticipant(id, profileId, awardCategory, awardPosition) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/award?awardCategory=${awardCategory||''}&awardPosition=${awardPosition||''}`, {}, this.getConfig());
    },
    disconnectFromOnDemandRace(id, raceId, profileId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/participants/${profileId}/races/${raceId}`, this.getConfig());
    },
    rewardBadge(id, raceId, profileId, request) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/races/${raceId}/reward-badge`, request, this.getConfig());
    },
    syncTeamsForParticipant(id, profileId) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/syncteams`, {}, this.getConfig());
    },
    joinTeamsForParticipant(id, profileId, groupId) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/jointeam`, qs.stringify({groupId:groupId}), this.getConfig());
    },
    getProfileActivities(id, profileId, continuationToken) {
        //return services.http.get(`/v1.0/eventmanager/${id}/participants/${profileId}/activities`, this.getConfig());
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/paged`, qs.stringify({continuationToken:continuationToken}), this.getConfig());
    },
    getParticipantActivityDetails(id, profileId, activityId) {
        return services.http.get(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}`, this.getConfig());
    },
    addParticipantActivity(id, profileId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/activities`, model, this.getConfig());
    },
    editParticipantActivity(id, profileId, activityId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}`, model, this.getConfig());
    },
    deleteParticipantActivity(id, profileId, activityId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}`, this.getConfig());
    },
    approveParticipantActivityDetails(id, profileId, activityId, raceId, badgeId) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}/approve?raceId=${raceId||''}&badgeId=${badgeId||''}`, this.getConfig());
    },
    rejectParticipantActivityDetails(id, profileId, activityId) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}/reject`, this.getConfig());
    },
    deleteResult(id, raceId, resultId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/${resultId}/disqualify`, {}, this.getConfig());
    },
    recalculateResult(id, raceId, resultId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/${resultId}/recalculate`, {}, this.getConfig());
    },
    deleteProfileFromTeamResultByName(id, raceId, resultId, name, score) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/${resultId}/profiles`, qs.stringify({name:name, score: score}), this.getConfig());
    },
    deleteProfileFromTeamResult(id, raceId, resultId, profileId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/${raceId}/${resultId}/profiles/${profileId}`, this.getConfig());
    },
    getGroupParticipants(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/groupparticipants`, this.getConfig());
    },
    deleteGroupParticipant(id, groupId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/groupparticipants/${groupId}`, this.getConfig());
    },
    updateGroupParticipant(id, groupId, newName) {
        return services.http.put(`/v1.0/eventmanager/${id}/groupparticipants/${groupId}`, qs.stringify({name:newName}), this.getConfig());
    },
    createTeam(id, teamName, teamCategory) {
        return services.http.post(`/v1.0/eventmanager/${id}/groupparticipants`, qs.stringify({name:teamName, category: teamCategory}), this.getConfig());
    },
    mergeTeams(id, groupIds) {
        return services.http.post(`/v1.0/eventmanager/${id}/groupparticipants/merge`, qs.stringify({groupIds:groupIds}), this.getConfig());
    },
    getTeamAssigment(id, includedGroupIds) {
        return services.http.get(`/v1.0/eventmanager/${id}/teamassignment?${ qs.stringify({includedGroupIds:includedGroupIds})}`, this.getConfig());
    },
    saveTeamAssigment(id, request) {
        return services.http.post(`/v1.0/eventmanager/${id}/teamassignment`, request, this.getConfig());
    },
    runRandomTeamAssignment(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/teamassignment/randomize`, {}, this.getConfig());
    },
    getTeamPermissions(id, groupId) {
        return services.http.get(`/v1.0/eventmanager/${id}/teams/${groupId}/permissions`, this.getConfig());
    },
    addTeamPermissions(id, groupId, profileId, roles) {
        return services.http.put(`/v1.0/eventmanager/${id}/teams/${groupId}/permissions/${profileId}`, qs.stringify({ roles: roles}), this.getConfig());
    },
    deleteTeamPermissions(id, groupId, profileId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/teams/${groupId}/permissions/${profileId}`, this.getConfig());
    },
    getJoinInfo(id, code) {
        return services.http.get(`/v1.0/eventmanager/${id}/joininfo?code=${code||''}`, this.getConfig());
    },
    getAllowlist(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/allowlist?raceId=${raceId||''}`, this.getConfig());
    },
    putAllowlist(id, raceId, data) {
        return services.http.put(`/v1.0/eventmanager/${id}/allowlist?raceId=${raceId||''}`, qs.stringify({ allowlist: data}), this.getConfig());
    },
    getDenylist(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/denylist`, this.getConfig());
    },
    releaseCode(id, code) {
        return services.http.delete(`/v1.0/eventmanager/${id}/denylist/${code||''}`, this.getConfig());
    },

    getPermissions(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/permissions`, this.getConfig());
    },
    addPermissions(id, profileId, roles) {
        return services.http.put(`/v1.0/eventmanager/${id}/permissions/${profileId}`, qs.stringify({ roles: roles}), this.getConfig());
    },
    deletePermissions(id, profileId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/permissions/${profileId}`, this.getConfig());
    },
    inviteAdmin(id, email, roles) {
        return services.http.post(`/v1.0/eventmanager/${id}/permissions/invite`, qs.stringify({ email: email, roles: roles}), this.getConfig());
    },
    acceptAdminInvite(id, email, token) {
        return services.http.post(`/v1.0/eventmanager/${id}/permissions/accept-invite`, qs.stringify({ email: email, token: token}), this.getConfig());
    },

    exportReport(id, report) {
        return services.http.get(`/v1.0/eventmanager/${id}/export/${report}`, { ...this.getConfig(), responseType: 'blob' });
    },
    exportRaceReport(id, raceId, report, groupId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/export/${report}?groupId=${groupId||''}`, { ...this.getConfig(), responseType: 'blob' });
    },
    sendRaceCompletedEmail(id, raceId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/email/completed`, {}, this.getConfig());
    },
    sendTestEmail(relativeUrl) {
        return services.http.post(`/v1.0/eventmanager/${relativeUrl}`, {}, this.getConfig());
    },
    sendRaceCertificateEmail(id, raceId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/email/certificate`, {}, this.getConfig());
    },
    sendBibEmail(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/bib`, {}, this.getConfig());
    },
    sendInviteEventEmail(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/invite`, {}, this.getConfig());
    },
    sendFriendInviteEmail(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/friendinvite`, {}, this.getConfig());
    },
    sendInviteEventEmailToCode(id, code) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/invite/${code||''}`, {}, this.getConfig());
    },
    reinviteUnconnected(id, code) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/invite/unconnected`, {}, this.getConfig());
    },

    getPromoEmailTemplate(id, type) {
        return services.http.get(`/v1.0/eventmanager/${id}/promo/email/${type}`, this.getConfig());
    },
    sendPromoEmailTemplate(id, type) {
        return services.http.post(`/v1.0/eventmanager/${id}/promo/email/${type}`, {}, this.getConfig());
    },
    generatePromoPdfTemplate(id, type, force) {
        return services.http.post(`/v1.0/eventmanager/${id}/promo/pdf/${type}?force=${force||''}`, {}, this.getConfig());
    },
    generatePromoImageTemplate(id, type, force) {
        return services.http.post(`/v1.0/eventmanager/${id}/promo/image/${type}?force=${force||''}`, {}, this.getConfig());
    },

    moderatePhoto(id, photoId, action) {
        return services.http.post(`/v1.0/eventmanager/${id}/photos/${photoId}/${action}`, this.getConfig());
    },
    putPhotoOverlays(id, overlays) {
        return services.http.put(`/v1.0/eventmanager/${id}/overlays`, overlays, this.getConfig());
    },
    uploadPhotoOverlay(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/overlays/image`, model, this.getConfig());
    },
    getVideos(id, adminVideosOnly) {
        return services.http.get(`/v1.0/eventmanager/${id}/videos?adminVideosOnly=${adminVideosOnly||false}`, this.getConfig());
    },
    deleteVideo(id, videoId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/videos/${videoId}`, this.getConfig());
    },

    getFeed(id, type, continuationToken) {
        return services.http.get(`/v1.0/eventmanager/${id}/feed?type=${type||''}&continuationToken=${encodeURIComponent(continuationToken||'')}`, this.getConfig());
    },
    postToFeed(id, request) {
        return services.http.post(`/v1.0/eventmanager/${id}/feed`, request, this.getConfig());
    },
    editFeedItem(id, feedItemId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/feed/${feedItemId}`, model, this.getConfig());
    },
    deleteFeedItem(id, feedItemId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/feed/${feedItemId}`, this.getConfig());
    },
    deleteFeedItemComment(id, feedItemId, commentFeedItemId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/feed/${feedItemId}/comments/${commentFeedItemId}`, this.getConfig());
    },
    moderateFeedItem(id, feedItemId, action) {
        return services.http.post(`/v1.0/eventmanager/${id}/feed/${feedItemId}/${action}`, model, this.getConfig());
    },

    getOAuthClient(id, provider) {
        return services.http.get(`/v1.0/eventmanager/${id}/oauthclients/${provider}`, this.getConfig());
    },
    putOAuthClient(id, provider, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/oauthclients/${provider}`, model, this.getConfig());
    },

    getActivityConversions(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/conversions`, this.getConfig());
    },
    putActivityConversions(id, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/conversions`, model, this.getConfig());
    },

    putActivityTypes(id, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/activity-types`, model, this.getConfig());
    },

    putWebhooks(id, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/webhooks`, model, this.getConfig());
    },

    getBibPreview(id, participantId) {
        return services.http.get(`/v1.0/eventmanager/${id}/bib/preview?participantId=${participantId||''}`, { ...this.getConfig(), responseType: 'blob' });
    },

    getCertificatePreview(id, raceId, resultId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/certificate/preview?resultId=${resultId||''}`, { ...this.getConfig(), responseType: 'blob' });
    },

    getRelaySlots(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/relayslots`, this.getConfig());
    },
    putRelaySlots(id, raceId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/${raceId}/relayslots`, model, this.getConfig());
    },

    getRaceResults(id, raceId, page) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/results?page=${page||''}`, this.getConfig());
    },
    getResultColumns(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/columns`, this.getConfig());
    },
    putResultColumns(id, raceId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/${raceId}/columns`, model, this.getConfig());
    },
    cloneResultColumns(id, raceId, raceIds) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/columns/clone`, qs.stringify({ raceIds: raceIds}), this.getConfig());
    },

    putRaceTiers(id, raceId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/${raceId}/tiers`, model, this.getConfig());
    },
    putRaceTimeSlots(id, raceId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/${raceId}/timeslots`, model, this.getConfig());
    },
    putRaceCompletionBadge(id, raceId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/${raceId}/completion-badge`, model, this.getConfig());
    },
    postRaceClone(id, raceId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/clone`, model, this.getConfig());
    },

    getBadges(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/badges`, this.getConfig());
    },
    putBadge(id, raceId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/${raceId}/badges`, model, this.getConfig());
    },
    previewBadge(id, raceId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/preview`, model, this.getConfig());
    },
    deleteBadge(id, raceId, badgeId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}`, this.getConfig());
    },
    sendBadgeEmail(id, raceId, badgeId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}/email`, {}, this.getConfig());
    },
    uploadBadgeImage(id, raceId, badgeId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}/image`, model, this.getConfig());
    },
    rewardCollectiveBadge(id, raceId, badgeId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}/reward-collective`, {}, this.getConfig());
    },
    uploadBadgeDetailsImage(id, raceId, badgeId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}/detailsimage`, model, this.getConfig());
    },
    getBadgeClaimLink(id, raceId, badgeId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}/claim-link`, this.getConfig());
    },
    copyBadges(id, fromRaceId, toRaceId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${fromRaceId}/badges/copy?toRaceId=${toRaceId}`, {}, this.getConfig());
    },

    integrationsConfigureApp(id, request) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/app`, request, this.getConfig());
    },
    integrationsConfigureSso(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/sso`, model, this.getConfig());
    },
    integrationsEventsComEvents(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/integrations/eventscom/events`, this.getConfig());
    },
    integrationsEventsComConnect(id, eventsComProductId) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/eventscom/events/${eventsComProductId}`, {}, this.getConfig());
    },
    integrationsEventsComSync(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/eventscom/sync`, {}, this.getConfig());
    },
    integrationsClassySync(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/classy/sync`, {}, this.getConfig());
    },
    integrationsMiroConnect(id, request) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/miro/connect`, request, this.getConfig());
    },
    integrationsTeamsSync(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/teams/sync`, {}, this.getConfig());
    },
    integrationsTeamsVerify(teamsInfo) {
        return services.http.post(`/v1.0/eventmanager/integrations/teams/validate`, teamsInfo, this.getConfig());
    },

    getAds() {
        return services.http.get(`/v1.0/eventmanager/ads`, this.getConfig());
    },
    postAd(model) {
        return services.http.post(`/v1.0/eventmanager/ads`, model, this.getConfig());
    },
    putAd(adId, model) {
        return services.http.put(`/v1.0/eventmanager/ads/${adId}`, model, this.getConfig());
    },
    deleteAd(adId) {
        return services.http.delete(`/v1.0/eventmanager/ads/${adId}`, this.getConfig());
    },
    uploadAdImage(adId, model) {
        return services.http.post(`/v1.0/eventmanager/ads/${adId}/image`, model, this.getConfig());
    },

}